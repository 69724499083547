// cspell:disable
/* eslint-disable max-len */
module.exports = {
  HomePage: {
    url: '/',
    component: 'src/pagesComponents/home/HomePageV5.tsx',
  },

  HomePageTilly: {
    url: '/tilly/',
    component: 'src/pagesComponents/home/HomePageTilly.js',
    seo: {
      title: 'Votre Artisan couturier - cordonnier, à domicile | Tilli',
      description: 'Réservez les services de nos couturiers et cordonniers experts avec Tilli. Retouches et réparations de vêtements, sacs et chaussures. Nettoyage de sacs et chaussures. Service à domicile ou par envoi de colis, au plus près de vous. Obtenez un devis en ligne en 2 min pour un service rapide et personnalisé.',
      english: {
        title: 'Artisan Tailoring & Cobbler Services at Your Door | Tilli',
        description: 'Book expert tailor and cobbler services with Tilli. Alterations and repairs for clothing, bags, and shoes. Restoration and cleaning services for bags and shoes. At-home service or parcel delivery, close to you. Get an online quote in 2 minutes for fast and personalized service.',
      },
      portuguese: {
        title: 'O seu Artesão costureiro e sapateiro à porta | Tilli',
        description: 'Reserve os serviços dos nossos costureiros/costureiras e sapateiros especializados com a Tilli. Alterações e reparos em roupas, bolsas e sapatos. Restauração e limpeza de bolsas e sapatos. Serviço em casa ou envio de pacotes, perto de você. Obtenha um orçamento online em 2 minutos para um serviço rápido e personalizado.',
      },
      german: {
        title: 'Dein Schneider und Schuhmacher direkt zu dir nach Hause | Tilli',
        description: 'Buchen Sie die Dienste unserer Experten für Schneider und Schuhmacher bei Tilli. Änderungen und Reparaturen von Kleidung, Taschen und Schuhen. Restaurierung und Reinigung von Taschen und Schuhen. Service vor Ort oder Paketsendung, in Ihrer Nähe. Holen Sie sich ein Online-Angebot in 2 Minuten für schnellen und personalisierten Service.',
      },
      italian: {
        title: 'Il tuo Artigiano sarto e calzolaio a casa tua | Tilli',
        description: 'Prenota i servizi dei nostri esperti sarti e calzolai con Tilli. Modifiche e riparazioni di abiti, borse e scarpe. Restauro e pulizia di borse e scarpe. Servizio a domicilio o spedizione di pacchi, vicino a te. Ottieni un preventivo online in 2 minuti per un servizio rapido e personalizzato.',
      },
      spanish: {
        title: 'Tilli | Tu Artesano sastre y zapatero, a domicilio | Tilli',
        description: 'Reserva los servicios de nuestros sastres y zapateros expertos con Tilli. Arreglos y reparaciones de ropa, bolsos y zapatos. Restauración y limpieza de bolsos y zapatos. Servicio a domicilio o envío de paquetes, cerca de ti. Obtén un presupuesto online en 2 minutos para un servicio rápido y personalizado.',
      },
    },
  },

  HowItWorksSEOPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retoucherie-a-paris-et-marseille/',
    component: 'src/pagesComponents/home/HowItWorksPage.tsx',
    seo: {
      title: 'Retoucherie et cordonnerie à domicile ou par envoi de colis | Tilli',
      description: 'Vous recherchez un retoucheur ou cordonnier à proximité ? Tilli vous simplifie la vie : entrez votre code postal et choisissez votre produit (sac, chaussures ou vêtements). Programmez un rendez-vous à domicile avec un artisan ou envoyez votre colis pour des retouches, réparations et nettoyages experts.',
      english: {
        title: 'At-home or parcel tailoring and cobbler services – Discover Tilli',
        description: 'Looking for a tailor or cobbler near you? Tilli makes it easy: enter your postal code, choose your product (bag, shoes, or clothing), and schedule an at-home appointment with an expert artisan or send your parcel for expert alterations, repairs, and cleaning.',
      },
      portuguese: {
        title: 'Descubra Tilli, o serviço de retouche e reparo',
        description: 'Um serviço simples, de alta qualidade e transparente: 1. Faça um agendamento - 2. Converse com seu Tilliste - 3. Recupere seus itens',
      },
      german: {
        title: 'Entdecken Sie Tilli, den Service für Anpassungen und Reparaturen',
        description: 'Ein einfaches, hochwertiges und transparentes Service: 1. Termin vereinbaren - 2. Treffen Sie Ihren Tillisten - 3. Holen Sie Ihre Artikel zurück',
      },
      italian: {
        title: 'Scopri Tilli, il servizio di adattamento e riparazione',
        description: 'Un servizio semplice, di alta qualità e trasparente: 1. Pianificare un appuntamento - 2. Conversare con il tuo Tilliste - 3. Recuperare i tuoi articoli',
      },
      spanish: {
        title: 'Descubre Tilli, el servicio de ajuste y reparación',
        description: 'Un servicio simple, de alta calidad y transparente: 1. Hacer una cita - 2. Conversar con tu Tilliste - 3. Recuperar tus artículos',
      },
    },
  },

  HowItWorksPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retouche-et-reparation/',
    component: 'src/pagesComponents/home/HowItWorksPage.tsx',
    seo: {
      title: 'Retoucherie et cordonnerie à domicile ou par envoi de colis - découvrez Tilli.',
      description: 'Vous recherchez un retoucheur ou cordonnier à proximité ? Tilli vous simplifie la vie : entrez votre code postal et choisissez votre produit (sac, chaussures ou vêtements). Programmez un rendez-vous à domicile avec un artisan ou envoyez votre colis pour des retouches, réparations et nettoyages experts.',
      english: {
        title: 'At-home or Parcel Tailoring and Cobbler Services – Discover Tilli.',
        description: 'Looking for a tailor or cobbler near you? Tilli makes it easy: enter your postal code, choose your product (bag, shoes, or clothing), and schedule an at-home appointment with an expert artisan or send your parcel for expert alterations, repairs, and cleaning.',
      },
      portuguese: {
        title: 'Descubra Tilli, o serviço de retouche e reparo',
        description: 'Um serviço simples, de alta qualidade e transparente: 1. Faça um agendamento - 2. Converse com seu Tilliste - 3. Recupere seus itens',
      },
      german: {
        title: 'Entdecken Sie Tilli, den Service für Anpassungen und Reparaturen',
        description: 'Ein einfaches, hochwertiges und transparentes Service: 1. Termin vereinbaren - 2. Treffen Sie Ihren Tillisten - 3. Holen Sie Ihre Artikel zurück',
      },
      italian: {
        title: 'Scopri Tilli, il servizio di adattamento e riparazione',
        description: 'Un servizio semplice, di alta qualità e trasparente: 1. Pianificare un appuntamento - 2. Conversare con il tuo Tilliste - 3. Recuperare i tuoi articoli',
      },
      spanish: {
        title: 'Descubre Tilli, el servicio de ajuste y reparación',
        description: 'Un servicio simple, de alta calidad y transparente: 1. Hacer una cita - 2. Conversar con tu Tilliste - 3. Recuperar tus artículos',
      },
    },
  },

  AboutPage: {
    url: '/a-propos-de-tilli/',
    component: 'src/pagesComponents/home/AboutPage.js',
    seo: {
      title: 'Notre histoire, nos artisans et nos valeurs | Tilli',
      description: 'Tilli, c’est l’histoire d’une passion pour l’artisanat et le soin des vêtements, sacs et chaussures. Nos couturiers, couturières et cordonniers experts vous offrent des services de retouche, réparation, nettoyage et entretien directement à domicile ou par envoi de colis.',
      english: {
        title: 'Our Story, Our Artisans, and Our Values | Tilli',
        description: 'Tilli is a story of passion for craftsmanship and the care of clothes, bags, and shoes. Our expert tailors, seamstresses, and cobblers offer alteration, repair, cleaning, and maintenance services directly at your doorstep or via parcel delivery.',
      },
      portuguese: {
        title: 'Sobre: descubra a história por trás de Tilli',
        description: 'Na Tilli, todos compartilham um objetivo comum: trazer o ofício de couturier para o século XXI digitalizando-o. O couturier troca diretamente com o cliente para manter a qualidade e proporcionar uma experiência única!',
      },
      german: {
        title: 'Über: Entdecken Sie die Geschichte von Tilli',
        description: 'Bei Tilli teilen wir uns ein gemeinsames Ziel: den Beruf des Couturiers in die 21. Jahrhundert zu digitalisieren. Der Couturier tauscht direkt mit dem Kunden, um Qualität und eine einzigartige Erfahrung zu gewährleisten!',
      },
      italian: {
        title: 'Chi siamo: scopri la storia dietro Tilli',
        description: 'A Tilli, tutti condividono un obiettivo comune: portare il mestiere del couturiere al 21° secolo digitalizzandolo. Il couturiere scambia direttamente con il cliente per mantenere la qualità e offrire un’esperienza unica!',
      },
      spanish: {
        title: 'Sobre: descubre la historia detrás de Tilli',
        description: 'En Tilli, todos compartimos un objetivo común: llevar el oficio del couturier al siglo XXI digitalizándolo. El couturier se comunica directamente con el cliente para mantener la calidad y proporcionar una experiencia única!',
      },
    },
  },

  BeTillistePage: {
    url: '/devenir-couturier/',
    component: 'src/pagesComponents/home/BeTillistePage.js',
    seo: {
      title: 'Rejoignez notre réseau d’Artisans couturiers - cordonniers | Tilli',
      description: 'Chez Tilli, nous accueillons tous les couturiers, couturières et cordonniers, quelle que soit votre spécialité ou expérience. Rejoignez notre réseau freelance et accédez à des missions rémunérées près de chez vous, avec paiements sécurisés et prix garantis.',
      english: {
        title: 'Join our Network of Expert Tailors, Seamstresses and Cobblers | Tilli',
        description: 'At Tilli, we welcome all tailors, seamstresses, and cobblers—whatever your specialty or experience. Join our freelance network and get paid assignments near you with secure payments and guaranteed rates.',
      },
      portuguese: {
        title: 'Seja um Couturier Freelance | Tilli',
        description: 'Tudo o que você precisa saber sobre o ofício de couturier Tilli. Trabalhe quando quiser, com total simplicidade. Estamos recrutando em Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc.',
      },
      german: {
        title: 'Werden Sie ein Freelance Couturier | Tilli',
        description: 'Alles, was Sie über die Berufsgruppe Couturier von Tilli wissen müssen. Arbeiten Sie, wann Sie möchten, mit vollständiger Einfachheit. Wir suchen in Paris, Lille, Straßburg, Nizza, Cannes, Nantes usw.',
      },
      italian: {
        title: 'Diventa un Couturier Freelance | Tilli',
        description: 'Tutto ciò che devi sapere sul mestiere di couturier di Tilli. Lavorate quando volete, con totalità di semplicità. Cerchiamo in Parigi, Lille, Strasbourg, Nice, Cannes, Nantes, ecc.',
      },
      spanish: {
        title: 'Ser un Couturier Freelance | Tilli',
        description: 'Todo lo que necesitas saber sobre el oficio de couturier de Tilli. Trabajen cuando quieran, con total simplicidad. Buscamos en París, Lille, Estrasburgo, Niza, Cannes, Nantes, etc.',
      },
    },
  },

  CustomerFeedbacksPage: {
    url: '/avis-de-nos-clients/',
    component: 'src/pagesComponents/home/CustomerFeedbacksPage.js',
  },

  LegalTerms: {
    url: '/mentions-legales/',
    component: 'src/pagesComponents/home/LegalTerms.js',
    seo: {
      title: 'Mentions Légales & Conditions Générales | Tilli',
      description: 'Toutes les informations légales de Tilli.',
      english: {
        title: 'Legal Terms & General Conditions | Tilli',
        description: 'Find all legal information about Tilli, including our terms of use and company details.',
      },
      portuguese: {
        title: 'Termos Legais & Condições Gerais | Tilli',
      },
      german: {
        title: 'Rechtliches & Allgemeine Bedingungen | Tilli',
      },
      italian: {
        title: 'Termini Legali & Condizioni Generali | Tilli',
      },
      spanish: {
        title: 'Términos Legales & Condiciones Generales | Tilli',
      },
    },
  },

  CookiesPrivacy: {
    url: '/politique-cookies/',
    component: 'src/pagesComponents/home/CookiesPrivacy.js',
    seo: {
      title: 'Politique de Cookies | Tilli',
      description: 'Consultez notre politique d’utilisation des cookies sur Tilli.',
      english: {
        title: 'Cookie Policy | Tilli',
        description: 'Read our cookie policy to understand how Tilli uses cookies to improve your experience.',
      },
      portuguese: {
        title: 'Política de Cookies | Tilli',
      },
      german: {
        title: 'Cookies-Richtlinie | Tilli',
      },
      italian: {
        title: 'Politica dei Cookies | Tilli',
      },
      spanish: {
        title: 'Política de Cookies | Tilli',
      },
    },
  },

  ShopifyPrivacy: {
    url: '/tilli-app-privacy-policy/',
    component: 'src/pagesComponents/home/ShopifyPrivacy.js',
    seo: {
      title: 'Tilli Privacy Policy',
      english: {
        title: 'Tilli Privacy Policy',
      },
      portuguese: {
        title: 'Política de Privacidade da Tilli',
      },
      german: {
        title: 'Tilli Datenschutzrichtlinie',
      },
      italian: {
        title: 'Politica di privacy di Tilli',
      },
      spanish: {
        title: 'Política de privacidad de Tilli',
      },
    },
  },

  ForgottenPasswordRequest: {
    url: '/mot-de-passe-oublie/',
    component: 'src/pagesComponents/myAccount/ForgottenPasswordRequest.js',
    seo: {
      title: 'Mot de passe oublié | Tilli',
      description: 'Réinitialisez votre mot de passe et accédez à votre compte Tilli.',
      english: {
        title: 'Reset Your Password | Tilli',
        description: 'Forgot your password? Reset it here and regain access to your Tilli account in just a few clicks.',
      },
      portuguese: {
        title: 'Senha esquecida | Tilli',
      },
      german: {
        title: 'Passwort vergessen | Tilli',
      },
      italian: {
        title: 'Password dimenticata | Tilli',
      },
      spanish: {
        title: 'Olvidé mi contraseña | Tilli',
      },
    },
  },

  ResetPassword: {
  // let the url without trailing slash, otherwise the page didn't load
    url: '/nouveau-mot-de-passe',
    component: 'src/pagesComponents/myAccount/ResetPasswordContainer.js',
    seo: { title: 'Nouveau mot de passe - Tilli' },
  },

  Insurance: {
    url: '/assurance-MMA-en-toute-serenite/',
    component: 'src/pagesComponents/home/Insurance.js',
    seo: {
      title: 'Tous vos Vêtements, Chaussures et Sacs sont assurés par MMA | Tilli',
      description: 'Toutes les commandes passées chez Tilli sont assurées par notre partenaire MMA. Tilli prend soin de ses clients et artisans couturiers - cordonniers !',
      english: {
        title: 'Your Clothes, Shoes, and Bags insured by MMA | Tilli',
        description: 'All orders with Tilli are fully insured by our partner MMA. We care for our customers, tailors, seamstresses and cobblers every step of the way!',
      },
      portuguese: {
        title: 'Todos seus vêtements cobertos pela MMA | Tilli',
        description: 'Todas as encomendas feitas com Tilli são cobertas pelo nosso parceiro MMA. Tilli cuida de seus clientes e couturiers!',
      },
      german: {
        title: 'Alle Ihre Kleidung von MMA abgedeckt | Tilli',
        description: 'Alle Aufträge, die bei Tilli platziert werden, sind von unserem Partner MMA abgedeckt. Tilli kümmert sich um seine Kunden und Couturiers!',
      },
      italian: {
        title: 'Tutti i vostri vestiti coperti da MMA | Tilli',
        description: 'Tutte le ordinazioni effettuate con Tilli sono coperte dal nostro partner MMA. Tilli si prende cura dei suoi clienti e couturiers!',
      },
      spanish: {
        title: 'Todos tus vestidos cubiertos por MMA | Tilli',
        description: 'Todas las órdenes colocadas con Tilli están cubiertas por nuestro partner MMA. Tilli se preocupa por sus clientes y couturiers!',
      },
    },
  },

  InsuranceTilliste: {
    url: '/assurance-MMA-tilliste/',
    component: 'src/pagesComponents/home/InsuranceTilliste.js',
    seo: {
      title: 'Tous nos Couturiers et Cordonniers sont assurés par MMA | Tilli',
      description: 'Travaillez l’esprit tranquille : toutes vos missions de couturier ou cordonnier Tilli sont assurées par MMA.',
      english: {
        title: 'Tilli tailors, seamstresses and cobblers are fully insured and protected by MMA | Tilli',
        description: 'Work securely as a freelance tailor, seamstress or cobbler with Tilli — every mission is fully insured by MMA.',
      },
      portuguese: {
        title: 'Todos nossos tillistes são cobertos pela MMA | Tilli',
        description: 'Como tilliste, trabalhe sem medo graças ao nosso parceiro MMA. Todas suas missões são cobertas pela MMA.',
      },
      german: {
        title: 'Alle unserer Tillisten sind von MMA abgedeckt | Tilli',
        description: 'Als Tilliste arbeiten Sie ohne Angst, da unser Partner MMA Ihnen hilft. Alle Ihre Aufträge sind von MMA abgedeckt.',
      },
      italian: {
        title: 'Tutti i nostri tillisti sono coperti da MMA | Tilli',
        description: 'Come tillista, lavora senza paura grazie al nostro partner MMA. Tutte le tue missioni sono coperte da MMA.',
      },
      spanish: {
        title: 'Todos nuestros tillistes están cubiertos por MMA | Tilli',
        description: 'Como tilliste, trabaja sin miedo gracias a nuestro partner MMA. Todas tus misiones están cubiertas por MMA.',
      },
    },
  },

  EventsPage: {
    url: '/nos-evenements/',
    component: 'src/templates/EventsTemplate.js',
    seo: {
      title: 'Nos événements et ateliers de personnalisation | Tilli',
      photo: '/eventPage.jpg',
      description: 'Participez à nos événements exclusifs et découvrez nos services de personnalisation : création de plaid personnalisé, sweat personnalisé, coussin personnalisé, broderie sur vêtement, et bien plus encore ! Profitez aussi de nos ateliers autour de l\'upcycling, la transformation et la réparation d\'articles.',
      english: {
        title: 'Our Customization Events and Workshops | Tilli',
        description: 'Join our exclusive events and discover our customization services: personalized blankets, custom sweatshirts, personalized cushions, garment embroidery, and much more! Also, take part in our workshops focused on upcycling, transformation, and repair of items.',
      },
      portuguese: {
        title: 'Nossos eventos | Tilli',
        description: 'Atelier de bordado, transformação de vestido de noiva, retouches e reparos em favor do Green Friday, criação de acessórios sob-medida... Para preparar as festas, nossos Tillistes estão em todos os fronts e multiplicam atividades nas nossas lojas parceiras.',
      },
      german: {
        title: 'Unsere Events | Tilli',
        description: 'Workshop Embroidery, Transformation eines Brautkleides, Reparaturen und Anpassungen im Auftrag von Green Friday, Erstellung von benutzerdefinierten Accessoires... Um die Feiertage zu bereiten, sind unsere Tillisten auf allen Fronten und multiplizieren Aktivitäten in unseren Partnern-Shops.',
      },
      italian: {
        title: 'I nostri eventi | Tilli',
        description: 'Atelier di bordatura, trasformazione di un vestito di nozze, riparazioni e adattamenti in favore di Green Friday, creazione di accessori personalizzati... Per preparare le feste, i nostri Tillisti sono su tutti i fronti e moltiplicano attività nelle nostre shop partner.',
      },
      spanish: {
        title: 'Nuestros eventos | Tilli',
        description: 'Taller de bordado, transformación de vestido de novia, reparaciones y ajustes en favor de Green Friday, creación de accesorios personalizados... Para preparar las fiestas, nuestros Tillistes están en todos los fronts y multiplican actividades en nuestras tiendas partner.',
      },
    },
  },

  PartnersPage: {
    url: '/ils-nous-font-confiance-les-partenaires-tilli/',
    component: 'src/pagesComponents/home/PartnersPage.js',
    seo: {
      title: 'Nos partenaires | Tilli',
      description: 'Zara, Asphalte, Soeur, Ba&sh et bien d\'autres font confiance à Tilli pour leurs retouches et réparations. Transformez l\'après - vente retail et online en fidélisation et rentabilité avec notre plateforme "Care & Repair" de services de soin, réparation et personnalisation omnicanaux.',
      english: {
        title: 'Our partners | Tilli',
        description: 'Zara, Asphalte, Soeur, Ba&sh, and many others trust Tilli for their alterations and repairs. Transform retail and online after-sales into customer loyalty and profitability with our "Care & Repair" platform offering omnichannel care, repair, and personalization services.',
      },
      portuguese: {
        title: 'Nossos parceiros | Tilli',
        description: 'Eles confiam em Tilli para suas retouches: Madura, Sézane, Isabel Marant, Violette Sauvage e muitos outros',
      },
      german: {
        title: 'Unsere Partner | Tilli',
        description: 'Sie vertrauen auf Tilli für ihre Reparaturen: Madura, Sézane, Isabel Marant, Violette Sauvage und viele andere',
      },
      italian: {
        title: 'I nostri partner | Tilli',
        description: 'Lasciano a Tilli il loro lavoro: Madura, Sézane, Isabel Marant, Violette Sauvage e molti altri',
      },
      spanish: {
        title: 'Nuestros partners | Tilli',
        description: 'Confían en Tilli para sus reparaciones: Madura, Sézane, Isabel Marant, Violette Sauvage y muchos más',
      },
    },
  },

  WhyBecomePartner: {
    url: '/tilli-votre-partenaire-retouche-reparation-ecommerce-boutique/',
    component: 'src/pagesComponents/home/WhyBecomePartner.tsx',
    seo: {
      title: 'Créez ou boostez votre service aftercare | Tilli',
      description: 'Intégrez facilement notre solution de retouche et de réparation et répondez à vos enjeux RSE et de fidélisation client. Plateforme retail et plug in online pour une solution plug & play, omnicanale et à votre image de marque. Connectez vos datas aftercare au passeport digital produit.',
      english: {
        title: 'Boost or Create Your Aftercare Service | Tilli',
        description: 'Easily integrate the most complete alteration and repair solution to address your CSR and customer loyalty goals. Retail platform and online plug-in for a plug & play, omnichannel solution tailored to your brand. Connect your aftercare data to the digital product passport.',
      },
      portuguese: {
        title: 'Por que escolher o Tilli como parceiro de retouches?',
        description: 'Você é uma marca ou uma loja, por que escolher o Tilli como parceiro de retouches? Para melhorar a experiência do cliente, para simplificar sua gestão e mais...',
      },
      german: {
        title: 'Warum wählen Sie uns als Partner für Reparaturen?',
        description: 'Sie sind eine Marke oder ein Geschäft, warum wählen Sie Tilli als Partner für Reparaturen? Um die Kundenerfahrung zu verbessern, um Ihre Verwaltung zu vereinfachen und mehr...',
      },
      italian: {
        title: 'Perché scegliere Tilli come partner per le riparazioni?',
        description: 'Sei una marca o un negozio, perché scegliere Tilli come partner per le riparazioni? Per migliorare l’esperienza del cliente, per semplificare la tua gestione e molto altro...',
      },
      spanish: {
        title: '¿Por qué elegirnos como partner de retouches?',
        description: '¿Eres una marca o una tienda, por qué elegirnos como partner de retouches? Para mejorar la experiencia del cliente, para simplificar tu gestión y más...',
      },
    },
  },

  FAQPage: {
    url: '/les-questions-les-plus-frequentes-tilli-retouche/',
    component: 'src/pagesComponents/home/FAQPage.js',
    seo: {
      title: 'FAQ : Réponses à vos questions | Tilli',
      description: 'Retouches, réparations, délais de livraison, tarifs, zones couvertes et plus encore ! Trouvez toutes les réponses à vos questions sur les services Tilli : retouches, réparations et personnalisation à domicile ou par envoi de colis.',
      english: {
        title: 'FAQ - Answers to your questions | Tilli',
        description: 'Alterations, repairs, delivery times, pricing, covered areas, and more! Find all the answers to your questions about Tilli services: alterations, repairs, and customization at home or by parcel.',
      },
      portuguese: {
        title: 'FAQ: Perguntas frequentes | Tilli',
        description: 'Retouches, prazo de entrega, cidades cobertas? Todas as respostas às suas perguntas sobre o serviço Tilli',
      },
      german: {
        title: 'FAQ: Häufig gestellte Fragen | Tilli',
        description: 'Reparaturen, Lieferzeit, abgedeckte Städte? Alle Antworten auf Ihre Fragen zum Tilli-Service',
      },
      italian: {
        title: 'FAQ: Domande frequenti | Tilli',
        description: 'Riparazioni, tempo di consegna, città coperte? Tutte le risposte alle tue domande sul servizio Tilli',
      },
    },
  },

  Invite: {
    url: '/i/',
    component: 'src/pagesComponents/home/Invite.js',
    seo: {
      title: '5€ offerts sur votre première commande Tilli',
      photo: '/photo-header-referral-code.jpg',
      description: 'Tilli | Votre Artisan couturier - cordonnier, à domicile',
      english: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli | Artisan Tailoring & Cobbler Services at Your Door',
      },
      portuguese: {
        title: '5€ de desconto na sua primeira encomenda Tilli',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli | O seu Artesão costureiro e sapateiro à porta',
      },
      german: {
        title: '5€ Rabatt auf deine erste Tilli-Bestellung',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli | Dein Handwerks-Schneider und Schuhmacher direkt zu dir nach Hause',
      },
      italian: {
        title: '5€ di sconto sul tuo primo ordine Tilli',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli | Il tuo Artigiano sarto e calzolaio a casa tua',
      },
      spanish: {
        title: '5€ de descuento en tu primer pedido de Tilli',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli | Tu Artesano sastre y zapatero a domicilio',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },

  HomePageClacDesDoigts: {
    url: '/clac-des-doigts/',
    component: 'src/pagesComponents/home/HomePageClacDesDoigts.js',
  },

  PricingsPage: {
    url: '/nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPage.js',
    seo: {
      title: 'Tarifs des services de retouche couture et cordonnerie | Tilli',
      description: 'Découvrez les prix des retouches couture et cordonnerie avec Tilli. Grille de tarif pour vos réparations de chaussures, semelles, talons, retouches de vêtements, rideaux, pantalons, broderies personnalisées, etc. Devis en ligne. Prix transparents pour des retouches et réparations de qualité à domicile.',
      english: {
        title: 'Tailoring and Cobbler Services Pricing | Tilli',
        description: 'Explore Tilli\'s pricing for tailoring and cobbler services.Find our price list for shoe repairs, soles, heels, clothing alterations, curtains, pants, wedding dress adjustments, custom embroidery, and more.Get an online quote with transparent pricing for high - quality alterations and repairs at home.',
      },
      portuguese: {
        title: 'Preços de retouches: Preços de retouches de roupas | Tilli',
        description: 'Preços de retouches de roupas: Descubra todos os nossos preços para a retouche de suas roupas & Cortinas ✅Costureira 7/7 das 7h às 22h',
      },
      german: {
        title: 'Preise für Reparaturen: Preise für Reparaturen von Kleidern | Tilli',
        description: 'Preise für Reparaturen von Kleidern: Entdecken Sie alle unsere Preise für die Reparatur Ihrer Kleider & Vorhänge ✅Schneider 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Prezzi per le riparazioni: Prezzi per le riparazioni di vestiti | Tilli',
        description: 'Prezzi per le riparazioni di vestiti: Scopri tutti i nostri prezzi per le riparazioni dei tuoi vestiti & tende ✅Costuriere 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Precios de reparaciones: Precios de reparaciones de ropa | Tilli',
        description: 'Precios de reparaciones de ropa: Descubra todos nuestros precios para la reparación de sus ropa & cortinas ✅Costurera 7/7 de 7 a 22',
      },
    },
  },

  PricingsPageFull: {
    url: '/tous-nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPageFull.js',
    seo: {
      title: 'Prix des services de cordonnerie et retouche couture | Tilli',
      description: 'Découvrez les prix des retouches couture et cordonnerie avec Tilli. Grille de tarif pour vos réparations de chaussures, semelles, talons, retouches de vêtements, rideaux, pantalons, robes de mariée, broderies personnalisées, etc. Devis en ligne. Prix transparents pour des retouches et réparations de qualité à domicile.',
      english: {
        title: 'Tailoring and Cobbler Services Pricing | Tilli',
        description: 'Explore Tilli\'s pricing for tailoring and cobbler services.Find our price list for shoe repairs, soles, heels, clothing alterations, curtains, pants, wedding dress adjustments, custom embroidery, and more.Get an online quote with transparent pricing for high - quality alterations and repairs at home.',
      },
      portuguese: {
        title: 'Preços dos serviços de costura e sapateiro | Tilli',
        description: 'Descubra os preços dos serviços de costura e sapateiro com a Tilli. Consulte nossa tabela de preços para alterações de roupas, cortinas, calças, ajustes de vestidos de noiva, reparos de sapatos, solas, saltos, bordados personalizados e muito mais. Solicite um orçamento online com preços transparentes para alterações e reparos de qualidade em casa.',
      },
      german: {
        title: 'Preise für Schneiderei- und Schuhreparaturdienstleistungen | Tilli',
        description: 'Entdecken Sie die Preise für Schneiderei- und Schuhreparaturdienste bei Tilli. Preisliste für Ihre Kleidungsänderungen, Vorhänge, Hosen, Hochzeitskleider, Schuhreparaturen, Sohlen, Absätze, personalisierte Stickerei und mehr. Online-Angebot. Transparente Preise für qualitativ hochwertige Reparaturen und Änderungen zu Hause.',
      },
      italian: {
        title: 'Prezzi dei servizi di sartoria e calzoleria | Tilli',
        description: 'Scopri i prezzi per i servizi di sartoria e calzoleria con Tilli. Tabella dei prezzi per le tue riparazioni di abbigliamento, tende, pantaloni, abiti da sposa, riparazioni delle scarpe, suole, tacchi, ricamo personalizzato, ecc. Preventivo online. Prezzi trasparenti per riparazioni e modifiche di qualità a domicilio.',
      },
      spanish: {
        title: 'Precios de servicios de costura y zapatería | Tilli',
        description: 'Descubre los precios de los servicios de costura y zapatería con Tilli. Tabla de tarifas para tus arreglos de ropa, cortinas, pantalones, vestidos de novia, reparación de zapatos, suelas, tacones, bordado personalizado, etc. Presupuesto en línea. Precios transparentes para reparaciones y alteraciones de calidad a domicilio.',
      },
    },
  },

  PricingsPageGL: {
    url: '/nos-tarifs-galeries-lafayette/',
    component: 'src/pagesComponents/home/PricingsPageGL.js',
    seo: {
      title: 'Prix des services de retouche couture et cordonnerie | Tilli',
      description: 'Découvrez les prix des retouches couture et cordonnerie avec Tilli. Grille de tarif pour vos retouches de vêtements, rideaux, pantalons, robes de mariée, réparation de chaussures, semelles, talons, broderie personnalisée, etc. Devis en ligne. Prix transparents pour des retouches et réparations de qualité à domicile.',
    },
  },

  PricingsPageWebView: {
    url: '/nos-tarifs-app/',
    component: 'src/pagesComponents/home/PricingsPageWebView.js',
  },

  OurArtisansPage: {
    url: '/nos-artisans/',
    component: 'src/pagesComponents/home/OurArtisansPage.js',
    seo: {
      title: 'Découvrez qui sont les artisans cordonniers et couturiers Tilli',
      description: 'Rencontrez nos cordonniers, couturiers, couturières, retoucheurs, retoucheuses Tilli. Spécialistes en retouches de vêtements, réparation et nettoyage de chaussures, ainsi que personnalisation, ils partagent leurs meilleurs souvenirs de missions avec Tilli et nos clients.',
      photo: '/tillistes-board.jpg',
      english: {
        title: 'Meet Tilli\'s expert tailors, seamstresses & cobblers',
        description: 'Meet Tilli\'s cobblers, tailors, seamstresses and alteration specialists.Experts in clothing alterations and repairs, shoe repairs and cleaning, and customization, they share their best mission memories with Tilli and our customers.Enjoy high - quality services tailored to you.',
      },
      portuguese: {
        title: 'Our couturiers: their words | Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      german: {
        title: 'Our couturiers: their words | Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      italian: {
        title: 'Our couturiers: their words | Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      spanish: {
        title: 'Our couturiers: their words | Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
    },
  },

  JobOfferPage: {
    url: '/rejoindre-aventure-tilli/',
    component: 'src/pagesComponents/home/JobOfferPage.js',
    seo: {
      title: 'Rejoignez l\'aventure Tilli: toutes nos offres de stage et d\'emploi',
      description: 'Vous êtes passionné par l\'artisanat, la mode circulaire et la tech ? Découvrez les opportunités de carrière chez Tilli! Nous proposons des stages et des offres d\'emploi dans la couture, la cordonnerie, et bien plus. Rejoignez notre équipe et contribuez à une plateforme en pleine croissance et un projet porteur de sens.',
      english: {
        title: 'Join the Tilli Adventure: all our Internship and Job Opportunities',
        description: 'Are you passionate about craftsmanship, sustainability in fashion, and innovative technologies? Explore exciting career opportunities at Tilli! We offer internships and job openings in tailoring, cobbling, customer service, and more. Join our team and contribute to a growing platform focused on quality, sustainability, and customer satisfaction.',
      },
    },
  },

  TilliXFitle: {
    url: '/tilli-et-fitle/',
    component: 'src/pagesComponents/home/TilliXFitle.js',
    seo: {
      title: 'Retouche Vêtement Fitle : Tilli Partenaire Fitle | Tilli',
      description: ' Qui n’a jamais eu peur de se tromper de taille en achetant ses vêtements sur Internet ou a été déçu en essayant chez lui son vêtement ? Tilli & Fitle décident donc de réunir leurs forces pour apporter une solution complète aux plateformes e-commerce.',
    },
  },

  LookBookPage: {
    url: '/lookbook-hiver-v1-2019-2020/',
    component: 'src/pagesComponents/home/LookBookPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
      english: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      portuguese: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      german: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      italian: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      spanish: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
    },
  },

  LookBookNewsletterPage: {
    url: '/lookbook-hiver-v1-2019-2020-newsletter/',
    component: 'src/pagesComponents/home/LookBookNewsletterPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
      english: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      portuguese: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      german: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      italian: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      spanish: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
    },
  },

  SustainableFashionPage: {
    url: '/la-mode-durable/',
    component: 'src/pagesComponents/home/SustainableFashionPage.js',
    seo: {
      title: 'La Mode Durable | Tilli',
      description: 'Expression féminine : prendre le temps de s’amuser et de laisser parler sa créativité pour donner une seconde vie à son dressing et son intérieur !',
      photo: '/last-collab-beryl.jpg',
      english: {
        title: 'Sustainable Fashion | Tilli',
        description: 'Feminine expression: take the time to enjoy and let your creativity speak to give a second life to your wardrobe and your interior!',
        photo: '/last-collab-beryl.jpg',
      },
      portuguese: {
        title: 'Moda Durável | Tilli',
        description: 'Expressão feminina: tome o tempo para se divertir e deixe sua criatividade falar para dar uma segunda vida ao seu guarda-roupa e ao seu interior!',
        photo: '/last-collab-beryl.jpg',
      },
      german: {
        title: 'Langfristige Mode | Tilli',
        description: 'Feminine expression: take the time to enjoy and let your creativity speak to give a second life to your wardrobe and your interior!',
        photo: '/last-collab-beryl.jpg',
      },
      italian: {
        title: 'Moda Durabile | Tilli',
        description: 'Espressione femminile: prendi il tempo per divertirti e lascia che la tua creatività parli per dare una seconda vita al tuo guardaroba e al tuo interno!',
        photo: '/last-collab-beryl.jpg',
      },
      spanish: {
        title: 'Moda Durável | Tilli',
        description: 'Expresión femenina: tome el tiempo para divertirse y deje que su creatividad hable para dar una segunda vida a su guardarropa y su interior!',
        photo: '/last-collab-beryl.jpg',
      },
    },
  },

  Retail: {
    url: '/nos-boutiques/',
    component: 'src/pagesComponents/home/Retail.js',
    seo: {
      title: 'Nos Ateliers de retouche, cordonnerie, maroquinerie | Tilli',
      description: 'Découvrez nos 2 ateliers Tilli et venez à la rencontre de notre équipe de cordonniers et retoucheurs experts. Situés rue Henry Monnier et rue Notre Dame de Lorette, nous offrons un service de retouche vêtement, réparation de sacs de luxe, nettoyage et réparation de chaussures en cuir ou daim et broderie personnalisée.',
      photo: '/atelier Tilli.jpg',
      english: {
        title: 'Alterations, Cobbler & Leather Repair Services | Tilli',
        description: 'Discover our expert cobbler and tailoring services at our two locations in Paris. Visit us at Rue Henry Monnier and Rue Notre Dame de Lorette, where we offer clothing alterations, bag repairs, leather and suede shoe repairs, and personalised embroidery services.',
        photo: '/atelier Tilli.jpg',
      },
      portuguese: {
        title: 'Nossas Lojas | Tilli',
        description: 'Descubra nossas 3 lojas: rua Henry Monnier, rua Notre Dame de Lorette e no Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      german: {
        title: 'Unsere Läden | Tilli',
        description: 'Entdecken Sie unsere 3 Läden: rue Henry Monnier, rue Notre Dame de Lorette und im Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      italian: {
        title: 'Le nostre Boutiques | Tilli',
        description: 'Scopri le nostre 3 boutiques: rue Henry Monnier, rue Notre Dame de Lorette e al Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      spanish: {
        title: 'Nuestras Tiendas | Tilli',
        description: 'Descubra nuestras 3 tiendas: rue Henry Monnier, rue Notre Dame de Lorette y en Printemps Haussmann',
        photo: '/boutique.jpg',
      },
    },
  },

  ThanksTillisteFormPage: {
    url: '/merci-inscription-tilliste/',
    component: 'src/pagesComponents/home/ThanksTillisteFormPage.js',
    isRouteNotIndexedBySearchEngine: true,
  },

  MainAlterationClothesPage: {
    url: '/retouche-vetement/',
    component: 'src/pagesComponents/home/LandingMainAlterationClothes.js',
    seo: {
      title: 'Retouche & Couture Vêtements, Retoucherie en ligne | Tilli',
      description: 'Réservez les services de nos couturières et tailleurs experts avec Tilli. Retouches de vêtements, couture sur mesure, ajustement de robes de mariée et ourlet de pantalon, à domicile ou en atelier. Obtenez un devis rapide en ligne et profitez de prix transparents pour des services de qualité près de chez vous.',
      english: {
        title: 'Alterations & Custom Tailoring for All Clothing | Tilli',
        description: 'Book the services of our expert seamstresses and tailors with Tilli. Clothing alterations, custom tailoring, bridal dress fittings, and pants hemming, at home or by parcel. Get a quick online quote and enjoy transparent pricing for quality services near you.',
      },
      portuguese: {
        title: 'Alterations & Costura Todos os Tipos de Roupas - Tilli',
        description: 'Tilli oferece um serviço de costureiro em casa para todas as suas retouches e pequenas costuras. ✅ Disponível 7/7 das 7h às 22h',
      },
      german: {
        title: 'Anpassungen & Couture Aller Artikel - Tilli',
        description: 'Anpassungen & Couture Aller Artikel: Tilli bietet einen Dienstleister zu Hause für alle Ihre Anpassungen und kleine Kostüme. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Anpassungen & Couture Tutti i Tipi di Abbigliamento - Tilli',
        description: 'Anpassungen & Couture Tutti i Tipi di Abbigliamento: Tilli offre un servizio di sarto a domicilio per tutte le tue modifiche e piccole costure. ✅ Disponibile 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Alteraciones & Couture Todos los Tipos de Ropa - Tilli',
        description: 'Tilli ofrece un servicio de sastre a domicilio para todas tus modificaciones y pequeñas costuras. ✅ Disponible 7/7 de 7 a 22',
      },
    },
  },

  MainRepairClothesPage: {
    url: '/reparation-vetement/',
    component: 'src/pagesComponents/home/LandingMainRepairClothes.tsx',
    seo: {
      title: 'Réparation de Vêtements : Fermetures Éclair, Trous, Remaillage | Tilli',
      description: 'Tilli propose des services experts pour réparer vos vêtements : recoudre un bouton, raccommoder un trou, remplacer une fermeture éclair cassée, réparer des fermetures éclair, et ajuster vos vêtements. Nous offrons des réparations rapides, de qualité et à prix transparents. Obtenez un devis en ligne dès aujourd\'hui!',
      english: {
        title: 'Clothing Repair: Zippers, Holes, and Mending | Tilli',
        description: 'Tilli offers expert clothing repair services: sew on buttons, mend holes, replace broken zippers, fix zippers, and adjust your garments. We provide fast, high-quality repairs at transparent prices. Get an online quote today for reliable and efficient service!',
      },
      portuguese: {
        title: 'Réparation & Couture Todos os Tipos de Roupas - Tilli',
        description: 'Tilli oferece um serviço de costureiro em casa para todas as suas retouches e pequenas costuras. ✅ Disponível 7/7 das 7h às 22h',
      },
      german: {
        title: 'Reparaturen & Couture Aller Artikel - Tilli',
        description: 'Reparaturen & Couture Aller Artikel: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und kleine Kostüme. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Riparature & Couture Tutti i Tipi di Abbigliamento - Tilli',
        description: 'Riparature & Couture Tutti i Tipi di Abbigliamento: Tilli offre un servizio di sarto a domicilio per tutte le tue riparature e piccole costure. ✅ Disponibile 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Reparaciones & Couture Todos los Tipos de Ropa - Tilli',
        description: 'Tilli ofrece un servicio de sastre a domicilio para todas tus reparaciones y pequeñas costuras. ✅ Disponible 7/7 de 7 a 22',
      },
    },
  },

  MainLeatherGoodsPage: {
    url: '/maroquinerie/',
    component: 'src/pagesComponents/home/LandingMainLeatherGoods.tsx',
    seo: {
      title: 'Réparation et Entretien de Sacs en Cuir, Daim et Maroquinerie | Tilli',
      description: 'Tilli offre les services de cordonniers experts pour entretenir et réparer vos sacs en cuir, daim et maroquinerie. Nettoyage, teinture, réparation de fermetures éclair et rénovation de sacs. Profitez d’un service rapide, de qualité et de prix transparents. Obtenez un devis en ligne dès aujourd’hui !',
      english: {
        title: 'Expert Leather Bag Cleaning, Repair & Restoration Services | Tilli',
        description: 'Tilli offers expert services for cleaning, dyeing, and repairing leather, suede, and leather goods. From zipper repairs to bag restoration, enjoy fast, high-quality service at transparent prices. Get a quick online quote today!',
      },
      portuguese: {
        title: 'Manutenção e Reparação de Acessórios em Couro: Manutenção e Reparação de Acessórios em Couro - Tilli',
        description: 'Manutenção e Reparação de Acessórios em Couro: Tilli oferece um serviço de manutenção e reparo de acessórios em couro disponível em todo o mundo graças ao envio de pacotes.',
      },
      german: {
        title: 'Maroquinerie Reparaturen & Wartung: Maroquinerie Reparaturen & Wartung Aller Artikel - Tilli',
        description: 'Maroquinerie Reparaturen & Wartung: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und Wartung von Lederwaren. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Manutenzione e Riparazione di Accessori in Pelle: Manutenzione e Riparazione di Accessori in Pelle - Tilli',
        description: 'Manutenzione e Riparazione di Accessori in Pelle: Tilli offre un servizio di manutenzione e riparazione di accessori in pelle disponibile in tutto il mondo grazie all\'invio di pacchi.',
      },
    },
  },

  MainLeatherGoodsSEAPage: {
    url: '/a/reparation/maroquinerie/',
    component: 'src/pagesComponents/home/LandingMainLeatherGoodsSEA.tsx',
    seo: {
      title: 'Artisans maroquiniers : Entretiens et réparation de vos accessoires en cuir - Tilli',
      description: "Entretien et réparation de vos accessoires en cuir : Tilli vous propose un service de maroquinerie de qualité disponible de partout grâce à l'envoi de colis.",
      english: {
        title: 'Leather Goods Repairs & Maintenance: Leather Goods Repairs & Maintenance All Types of Leather Goods - Tilli',
        description: 'Leather Goods Repairs & Maintenance: Tilli offers a leather goods repair and maintenance service available everywhere thanks to package delivery.',
      },
      portuguese: {
        title: 'Manutenção e Reparação de Acessórios em Couro: Manutenção e Reparação de Acessórios em Couro - Tilli',
        description: 'Manutenção e Reparação de Acessórios em Couro: Tilli oferece um serviço de manutenção e reparo de acessórios em couro disponível em todo o mundo graças ao envio de pacotes.',
      },
      german: {
        title: 'Maroquinerie Reparaturen & Wartung: Maroquinerie Reparaturen & Wartung Aller Artikel - Tilli',
        description: 'Maroquinerie Reparaturen & Wartung: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und Wartung von Lederwaren. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Manutenzione e Riparazione di Accessori in Pelle: Manutenzione e Riparazione di Accessori in Pelle - Tilli',
        description: 'Manutenzione e Riparazione di Accessori in Pelle: Tilli offre un servizio di manutenzione e riparazione di accessori in pelle disponibile in tutto il mondo grazie all\'invio di pacchi.',
      },
    },
  },

  MainShoesPage: {
    url: '/cordonnerie/',
    component: 'src/pagesComponents/home/LandingMainShoes.tsx',
    seo: {
      title: 'Cordonnerie : Nettoyage & Réparation de Chaussures et Sneakers | Tilli',
      description: 'Confiez vos chaussures à nos artisans cordonniers : nettoyage daim, cuir et sneakers, cirage, teinture, réparation de semelles ou talons. Obtenez un devis rapide en ligne et profitez du bonus réparation pour entretenir et prolonger la vie de vos chaussures.',
      english: {
        title: 'Shoe Cleaning & Repair - Leather, Suede & Sneakers | Tilli',
        description: 'Expert shoe care by Tilli: suede cleaning, leather care, sneaker cleaning, shoe dyeing, sole and heel repair. Get an instant online quote and enjoy bonus repair incentives. Restore, protect, and extend the life of your favorite shoes with our expert cobblers.',
      },
      portuguese: {
        title: 'Reparações e Manutenção de Sapatos: Reparações e Manutenção de Sapatos - Tilli',
        description: 'Reparações e Manutenção de Sapatos: Tilli oferece um serviço de reparo e manutenção de sapatos disponível em todo o mundo graças ao envio de pacotes.',
      },
      german: {
        title: 'Schuhreparaturen & Wartung: Schuhreparaturen & Wartung Aller Artikel - Tilli',
        description: 'Schuhreparaturen & Wartung: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und Wartung von Schuhen. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Riparature & Manutenzione di Scarpe: Riparature & Manutenzione di Scarpe - Tilli',
        description: 'Riparature & Manutenzione di Scarpe: Tilli offre un servizio di riparo e manutenzione di scarpe disponibile in tutto il mondo grazie all\'invio di pacchi.',
      },
      spanish: {
        title: 'Reparaciones y Mantenimiento de Zapatos: Reparaciones y Mantenimiento de Zapatos - Tilli',
        description: 'Reparaciones y Mantenimiento de Zapatos: Tilli ofrece un servicio de reparación y mantenimiento de zapatos disponible en todo el mundo gracias al envío de paquetes.',
      },
    },
  },

  MainShoesSEAPage: {
    url: 'a/reparation/cordonnerie/',
    component: 'src/pagesComponents/home/LandingMainShoesSEA.tsx',
    seo: {
      title: 'Artisans cordonniers : Entretiens et réparation de vos chaussures - Tilli',
      description: "Entretien et réparation de vos chaussures : Tilli vous propose un service de cordonnerie de qualité disponible de partout grâce à l'envoi de colis.",
      english: {
        title: 'Shoes Repairs & Maintenance: Shoes Repairs & Maintenance All Types of Shoes - Tilli',
        description: 'Shoes Repairs & Maintenance: Tilli offers a shoes repair and maintenance service available everywhere thanks to package delivery.',
      },
      portuguese: {
        title: 'Reparações e Manutenção de Sapatos: Reparações e Manutenção de Sapatos - Tilli',
        description: 'Reparações e Manutenção de Sapatos: Tilli oferece um serviço de reparo e manutenção de sapatos disponível em todo o mundo graças ao envio de pacotes.',
      },
      german: {
        title: 'Schuhreparaturen & Wartung: Schuhreparaturen & Wartung Aller Artikel - Tilli',
        description: 'Schuhreparaturen & Wartung: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und Wartung von Schuhen. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Riparature & Manutenzione di Scarpe: Riparature & Manutenzione di Scarpe - Tilli',
        description: 'Riparature & Manutenzione di Scarpe: Tilli offre un servizio di riparo e manutenzione di scarpe disponibile in tutto il mondo grazie all\'invio di pacchi.',
      },
      spanish: {
        title: 'Reparaciones y Mantenimiento de Zapatos: Reparaciones y Mantenimiento de Zapatos - Tilli',
        description: 'Reparaciones y Mantenimiento de Zapatos: Tilli ofrece un servicio de reparación y mantenimiento de zapatos disponible en todo el mundo gracias al envío de paquetes.',
      },
    },
  },

  CoutureServicesPage: {
    url: '/nos-services-de-couture/',
    component: 'src/pagesComponents/home/OurServices/CoutureServicesPage.js',
    seo: {
      title: 'Votre Couturier à domicile avec Tilli : Retoucherie en ligne',
      description: 'Retouches couture de vêtements, rideaux, accessoires : ourlet de pantalon ou de jean, ajustement de costume, robe ou manteau, réparation ou changement de fermeture éclair, bouton à recoudre. Confiez vos retouches à nos couturières et couturiers experts.',
      english: {
        title: 'Alterations Made Easy – Book Your At-Home Tailor with Tilli',
        description: 'Expert Alterations for Clothing, Curtains & Textiles: Hemming, Suit Adjustments, Zipper Repairs, Button Replacements. Book our skilled tailors and seamstresses for quality service.',
      },
      portuguese: {
        title: 'Nossos Serviços de Retouche - Tilli',
        description: 'Retouche e reparos de todos seus vêtements: borda, ajuste, transformação, etc.',
      },
      german: {
        title: 'Unsere Anpassungen Services - Tilli',
        description: 'Anpassungen und Reparaturen aller Ihrer Kleidung: Naht, Anpassung, Umwandlung, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Anpassamento - Tilli',
        description: 'Anpassamenti e riparazioni di tutta la vostra abbigliamento: cucitura, adattamento, trasformazione, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Ajuste - Tilli',
        description: 'Ajustes y reparaciones de todo su ropa: bordado, ajuste, transformación, etc.',
      },
    },
  },

  LeatherGoodsServicesPage: {
    url: '/nos-services-de-maroquinerie/',
    component: 'src/pagesComponents/home/OurServices/LeatherGoodsServicesPage.js',
    seo: {
      title: 'Réparation et Nettoyage de Sacs en Cuir, Daim, Maroquinerie | Tilli',
      description: 'Tilli met à votre service des artisans cordonniers spécialisés dans l’entretien et la réparation de sacs en cuir, daim et maroquinerie. Nettoyage, teinture, rénovation, cirage et réparation de fermetures éclair. Qualité artisanale, devis en ligne rapide.',
      english: {
        title: 'Leather Bag Cleaning, Repair & Restoration Services | Tilli',
        description: 'Give your leather, suede, and bags a second life with Tilli’s expert cleaning, dyeing, and repair services. Zippers, stains, restoration — we’ve got you covered. Fast service, fair prices. Get your quote online now!',
      },
      portuguese: {
        title: 'Nossos Serviços de Maroquinerie - Tilli',
        description: 'Manutenção e reparo de todos seus acessórios em couro: bolsa, cintura, sapatos, etc.',
      },
      german: {
        title: 'Unsere Maroquinerie Services - Tilli',
        description: 'Wartung und Reparatur aller Ihrer Lederwaren: Tasche, Gürtel, Schuhe, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Maroquinerie - Tilli',
        description: 'Manutenzione e riparazione di tutti i vostri accessori in pelle: borsa, cintura, scarpe, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Marroquinería - Tilli',
        description: 'Mantenimiento y reparación de todos sus accesorios en cuero: bolsa, cinturón, zapatos, etc.',
      },
    },
  },

  EmbroideryServicesPage: {
    url: '/nos-services-de-broderie/',
    component: 'src/pagesComponents/home/OurServices/EmbroideryServicesPage.js',
    seo: {
      title: 'Nos Services de Broderie Personnalisée | Tilli',
      description: 'Offrez une touche unique à vos sweats, hoodies, coussins, plaids ou polaires grâce à notre service de broderie personnalisée. Brodez un prénom, des initiales ou un message sur vos vêtements et accessoires. Personnalisation à l’unité et devis en ligne rapide.',
      english: {
        title: 'Custom Embroidery Services on Clothes & Accessories | Tilli',
        description: 'Add a personal touch with custom embroidery on hoodies, sweatshirts, cushions, blankets, or polar fleece. Personalize with a name, message or initials. Single-piece embroidery available. Get a quick online quote for your custom project.',
      },
      portuguese: {
        title: 'Nossos Serviços de Bordado - Tilli',
        description: 'Personalização de todos seus vêtements e acessórios: inicial, logotipo, etc.',
      },
      german: {
        title: 'Unsere Embroiderie Services - Tilli',
        description: 'Anpassungen und Reparaturen aller Ihrer Kleidung: Naht, Anpassung, Umwandlung, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Broderie - Tilli',
        description: 'Personalizzazione di tutta la vostra abbigliamento e accessori: iniziali, logo, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Bordado - Tilli',
        description: 'Personalización de todo su ropa y accesorios: iniciales, logotipo, etc.',
      },
    },
  },

  ShoesServicesPage: {
    url: '/nos-services-de-cordonnerie/',
    component: 'src/pagesComponents/home/OurServices/ShoesServicesPage.js',
    seo: {
      title: 'Nos Services de Cordonnerie pour vos Chaussures & Sneakers | Tilli',
      description: 'Confiez vos chaussures à nos artisans cordonniers : nettoyage cuir, daim et sneakers, cirage, teinture, réparation de semelles ou talons. Obtenez un devis rapide en ligne et profitez du bonus réparation pour prolonger la vie de vos chaussures.',
      english: {
        title: 'Our Shoes Cleaning, Polishing and Repair Services | Tilli',
        description: 'Expert shoe care by Tilli: suede and leather cleaning, sneaker cleaning, shoe polishing, dyeing, sole and heel repair. Get an instant online quote and enjoy bonus repair incentives. Protect and restore your shoes with expert craftsmanship.',
      },
      portuguese: {
        title: 'Nossos Serviços de Cordonnerie - Tilli',
        description: 'Manutenção e reparo de todos seus sapatos: salto, patim, polimento, etc.',
      },
      german: {
        title: 'Unsere Schuh Services - Tilli',
        description: 'Wartung und Reparatur aller Ihrer Schuhe: Heel, Pad, Polieren, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Cordonnerie - Tilli',
        description: 'Manutenzione e riparazione di tutti i vostri sapelli: salto, patim, polimento, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Cordonnería - Tilli',
        description: 'Mantenimiento y reparación de todos sus zapatos: salto, patim, polimento, etc.',
      },
    },
  },

  HomePagePackage: {
    url: '/service-colis/',
    component: 'src/pagesComponents/home/HomePagePackage.tsx',
    seo: {
      title: 'Service de couture et cordonnerie, sans vous déplacer | Tilli',
      description: 'Profitez de notre service de retouches, réparations et nettoyage à domicile avec envoi de colis. Nos couturiers et cordonniers experts s\'occupent de vos vêtements, chaussures, sacs et accessoires, et vous les renvoient impeccablement retouchés ou nettoyés rapidement.Service rapide et de qualité.',
      english: {
        title: 'Tailoring and Cobbler Services, Delivered to Your Door | Tilli',
        description: 'Enjoy our At-Home Alterations, Repairs, and Cleaning Service with Parcel Delivery. Our expert tailors and cobblers take care of your clothes, shoes, bags, and accessories, returning them impeccably altered or cleaned quickly. Fast, high-quality service guaranteed.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  HomePagePackageV2: {
    url: '/a/retouche-reparation/',
    component: 'src/pagesComponents/home/HomePagePackage.tsx',
    seo: {
      title: 'Tilli - Service de couturiers en envoi de colis',
      description: 'Un service simple, de qualité et transparent.',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  RepairBonusPage: {
    url: '/bonus-reparation/',
    component: 'src/pagesComponents/home/RepairBonusPage.js',
    seo: {
      title: 'Bonus Réparation : Profitez de Prix Réduits avec Tilli',
      description: 'Profitez du Bonus Réparation pour réduire le coût de la réparation de vos textiles et chaussures. Tous les cordonniers et couturiers Tilli sont agréés et donc inclus à la liste des magasins donnant accès à la prime, en toute simplicité pour vous.',
    },
  },

  LandingCurtainsPage: {
    url: '/retouche-rideaux/',
    component: 'src/templates/LandingCurtainsPage.tsx',
    seo: {
      title: 'Service de Retouche de Rideaux à Domicile | Tilli',
      description: 'Vous souhaitez raccourcir vos rideaux sans les couper ? Le service de retouche à domicile de Tilli propose des ourlets et ajustements professionnels. Profitez de résultats impeccables à prix abordables. Réservez votre retouche de rideaux dès aujourd\'hui!',
      english: {
        title: 'At-Home Curtain Tailoring: Hemming & Shortening Made Easy | Tilli',
        description: 'Need to shorten your curtains without cutting? Tilli’s at-home tailoring service offers expert curtain hemming, adjustments, and alterations. Get professional results at affordable prices. Book your curtain alteration today!',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  LaRubrique: {
    url: '/la-rubrique/',
    seo: {
      title: 'Blog, Actualités, Événements | Tilli',
      description: 'Tout savoir sur la retouche, la réparation et le nettoyage à domicile de vos vêtements, chaussures, sacs et accessoires. Comment obtenir le meilleur rendu, pour des vêtements et accessoires qui vont vous aller parfaitement et pour longtemps !',
      photo: '/la-rubrique.jpg',
      english: {
        title: 'Blog, News, Events | Tilli',
        description: 'Everything you need to know about at-home alterations, repairs, and cleaning for your clothes, shoes, bags, and accessories. Learn how to achieve the best results, for clothing and accessories that fit perfectly and last for years!',
      },
      portuguese: {
        title: 'A blogue de Tilli: todas as inspirações',
        description: 'Encontre inspiração para o seu guarda-roupa com base em suas roupas e materiais: bordados, bolsos, transformações de roupas…',
      },
      german: {
        title: 'Die Blog-Seite von Tilli: alle Inspirationen',
        description: 'Finde Inspiration für deinen Garderoben basierend auf deinen Kleidern und Materialien: Bordüre, Taschen, Umformungen von Kleidern…',
      },
      italian: {
        title: 'Il blog di Tilli: tutte le ispirazioni',
        description: 'Trova ispirazione per il tuo guardaroba basata sui tuoi vestiti e materiali: broderie, tasche, trasformazioni di vestiti…',
      },
      spanish: {
        title: 'El blog de Tilli: todas las inspiraciones',
        description: 'Encuentra inspiración para tu guardarropa basada en tus prendas y materiales: bordados, bolsillos, transformaciones de prendas…',
      },
    },
  },
};
