// cspell:disable
module.exports = {
  MyAccount: {
    url: '/mon-compte/',
    component: 'src/pagesComponents/myAccount/MyAccount.js',
    seo: {
      title: 'Mon compte - Tilli',
      description: 'Le récapitulatif de mes informations',
      english: {
        title: 'My account - Tilli',
        description: 'The summary of my information',
      },
      portuguese: {
        title: 'Meu conta - Tilli',
        description: 'O resumo de minhas informações',
      },
      german: {
        title: 'Mein Konto - Tilli',
        description: 'Die Zusammenfassung meiner Informationen',
      },
      italian: {
        title: 'Il mio account - Tilli',
        description: 'Il riepilogo delle mie informazioni',
      },
      spanish: {
        title: 'Mi cuenta - Tilli',
        description: 'El resumen de mis información',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },
  MyInfos: {
    url: '/mon-compte/mes-informations/',
    component: 'src/pagesComponents/myAccount/MyInfos.js',
    seo: {
      title: 'Mon informations - Tilli',
      description: 'Mes informations',
      english: {
        title: 'My informations - Tilli',
        description: 'My informations',
      },
      portuguese: {
        title: 'Meus informações - Tilli',
        description: 'Meus informações',
      },
      german: {
        title: 'Meine Informationen - Tilli',
        description: 'Meine Informationen',
      },
      italian: {
        title: 'Le mie informazioni - Tilli',
        description: 'Le mie informazioni',
      },
      spanish: {
        title: 'Mis informaciones - Tilli',
        description: 'Mis informaciones',
      },
    },
  },
  MyAddressesAndPayments: {
    url: '/mon-compte/mes-adresses-et-paiements/',
    component: 'src/pagesComponents/myAccount/MyAddressesAndPayments.js',
    seo: {
      title: 'Mes adresses et paiements - Tilli',
      description: 'Mes adresses et paiements',
      english: {
        title: 'My addresses and payments - Tilli',
        description: 'My addresses and payments',
      },
      portuguese: {
        title: 'Meus endereços e pagamentos - Tilli',
        description: 'Meus endereços e pagamentos',
      },
      german: {
        title: 'Meine Adressen und Zahlungen - Tilli',
        description: 'Meine Adressen und Zahlungen',
      },
      italian: {
        title: 'Le mie informazioni - Tilli',
        description: 'Le mie informazioni',
      },
      spanish: {
        title: 'Mis informaciones - Tilli',
        description: 'Mis informaciones',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },
  MyOrders: {
    url: '/mon-compte/mes-commandes/',
    component: 'src/pagesComponents/myAccount/MyOrders.js',
    seo: {
      title: 'Mes commandes - Tilli',
      description: 'Mes commandes',
      english: {
        title: 'My orders - Tilli',
        description: 'My orders',
      },
      portuguese: {
        title: 'Minhas encomendas - Tilli',
        description: 'Minhas encomendas',
      },
      german: {
        title: 'Meine Bestellungen - Tilli',
        description: 'Meine Bestellungen',
      },
      italian: {
        title: 'Le mie informazioni - Tilli',
        description: 'Le mie informazioni',
      },
      spanish: {
        title: 'Mis informaciones - Tilli',
        description: 'Mis informaciones',
      },
    },
  },
  MyOrderDetails: {
    url: '/mon-compte/details-de-la-commande',
    component: 'src/pagesComponents/myAccount/MyOrderDetails.js',
    seo: {
      title: "Détail d'une commande - Tilli",
      description: "Détail d'une commande",
      english: {
        title: 'Detail of an order - Tilli',
        description: 'Detail of an order',
      },
      portuguese: {
        title: 'Detalhes de uma encomenda - Tilli',
        description: 'Detalhes de uma encomenda',
      },
      german: {
        title: 'Detail einer Bestellung - Tilli',
        description: 'Detail einer Bestellung',
      },
      italian: {
        title: 'Dettagli di un ordine - Tilli',
        description: 'Dettagli di un ordine',
      },
      spanish: {
        title: 'Detalles de una orden - Tilli',
        description: 'Detalles de una orden',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },
  MyMessages: {
    url: '/mon-compte/mes-messages/',
    component: 'src/pagesComponents/myAccount/MyMessages.tsx',
    seo: {
      title: 'Mes messages - Tilli',
      description: 'Mes messages',
      english: {
        title: 'My messages - Tilli',
        description: 'My messages',
      },
      portuguese: {
        title: 'Meus mensagens - Tilli',
        description: 'Meus mensagens',
      },
      german: {
        title: 'Meine Nachrichten - Tilli',
        description: 'Meine Nachrichten',
      },
      italian: {
        title: 'Le mie informazioni - Tilli',
        description: 'Le mie informazioni',
      },
      spanish: {
        title: 'Mis informaciones - Tilli',
        description: 'Mis informaciones',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },
};
