import { isItemInDiscount } from 'tilli-core/dist/services/orderPriceUtils';
import { repairBonusDiscount } from 'tilli-core/dist/services/repairBonus';
import { Cloth, Item } from '../types/products-types';
import { Discount } from '../types/payments-types';
import { Order } from '../types/order-types';
import { isMultilingual } from '../../languages';

function isOrderEligibleToRepairBonus({ landingPageBrand, country = 'FR' }: { landingPageBrand: string; country: string }): boolean {
  return (!isMultilingual || country === 'FR') && landingPageBrand !== 'Lilysilk';
}

function isClothItemEligibleToRepairBonus(item: Item, orderDiscounts: Discount[]) {
  const orderRepairBonusDiscount = orderDiscounts?.find((discount) => discount.name === repairBonusDiscount.name);
  if (!orderRepairBonusDiscount) return false;
  return isItemInDiscount(orderRepairBonusDiscount, item.id);
}

function isClothHasItemEligibleToRepairBonus(cloth: Cloth, orderDiscounts: Discount[]) {
  return cloth.items.some((clothItem) => isClothItemEligibleToRepairBonus(clothItem, orderDiscounts));
}

function isOrderHasClothEligibleToRepairBonus(clothes: Cloth[], orderDiscounts: Discount[]) {
  return clothes?.some((orderCloth) => isClothHasItemEligibleToRepairBonus(orderCloth, orderDiscounts));
}

function getClothItemRepairBonusValue(item: Item, itemQuantity: number, orderDiscounts: Discount[]): number | undefined {
  const orderRepairBonusDiscount = orderDiscounts?.find((discount) => discount.name === repairBonusDiscount.name);
  if (!orderRepairBonusDiscount) return 0;
  return (orderRepairBonusDiscount.discountOnItems?.find((discountOnItem) => discountOnItem.itemIds.includes(item.id))?.value || 0) * itemQuantity;
}

function isRepairBonusClothItemHasPhotos(item: Item, orderDiscounts: Discount[]) {
  if (!isClothItemEligibleToRepairBonus(item, orderDiscounts)) return true;

  return item.photoBefore !== undefined;
}

function isRepairBonusClothHasPhotos(cloth: Cloth, orderDiscounts: Discount[]) {
  if (!isClothHasItemEligibleToRepairBonus(cloth, orderDiscounts)) return true;

  return cloth.photoBefore !== undefined && cloth.items.every((clothItem) => isRepairBonusClothItemHasPhotos(clothItem, orderDiscounts));
}

function isRepairBonusOrderCouldBeCreated(order: Order): boolean {
  if (!order.clothes) return true;
  if (!isOrderHasClothEligibleToRepairBonus(order.clothes, order.discounts)) return true;

  return order.clothes.every((orderCloth) => isRepairBonusClothHasPhotos(orderCloth, order.discounts));
}

export {
  isOrderEligibleToRepairBonus,
  isClothItemEligibleToRepairBonus,
  isOrderHasClothEligibleToRepairBonus,
  isClothHasItemEligibleToRepairBonus,
  getClothItemRepairBonusValue,
  isRepairBonusOrderCouldBeCreated,
  isRepairBonusClothHasPhotos,
};
