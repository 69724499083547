import { getClothTotal } from 'tilli-core/dist/services/orderPrice';
import {
  getTreeCreation, getTreeAlterationSuit, getTreeAlterationSuitPackage, getTreeChairCreation, getTreeSofaCreations,
} from 'tilli-core/dist/trees/treeHelpers';
import getTree from 'tilli-core/dist/trees/tree';
import getTreePackage from 'tilli-core/dist/trees/treePackage';

export const getFormattedSuitPiece = (index, cloth, suitPiece, problems) => ({
  ...cloth,
  index,
  selectedProblems: problems,
  selectedFabric: cloth.suitItems.filter(({ piece }) => piece === suitPiece).every(({ isNotSuit }) => isNotSuit) ? '' : 'suit',
  selectedLining: cloth.suitPieceLinings[suitPiece] || '',
  suitPiece,
  embroideries: cloth.suitPiecesEmbroideries[suitPiece]?.embroideries ?? [],
  activeEmbroideryFontSize: cloth.suitPiecesEmbroideries[suitPiece]?.activeEmbroideryFontSize ?? -1,
  activeEmbroideryDrawingSize: cloth.suitPiecesEmbroideries[suitPiece]?.activeEmbroideryDrawingSize ?? -1,
  isDrawingFull: cloth.suitPiecesEmbroideries[suitPiece]?.isDrawingFull ?? undefined,
  isDrawingEasy: cloth.suitPiecesEmbroideries[suitPiece]?.isDrawingEasy ?? undefined,
  upcyclingCategories: cloth.suitPiecesUpcycling[suitPiece]?.upcyclingCategories ?? [],
  upcyclingItems: cloth.suitPiecesUpcycling[suitPiece]?.upcyclingItems ?? [],
});

export const getClothesWithSuitPiecesAndSofaPartsSplitted = (t, clothes) => {
  const clothesWithSuitPiecesAndSofaPartsSplitted = [];
  clothes.forEach((cloth, index) => {
    const treeAlterationSuit = cloth.selectedProcess === 'AT_HOME' ? getTreeAlterationSuit(t) : getTreeAlterationSuitPackage(t);
    if (cloth.selectedCloth === 'cost' && ['alteration', 'repair', 'embroidery', 'transformation'].includes(cloth.selectedTypeOfWork)) {
      cloth.suitPieces.forEach((suitPiece) => {
        const problems = {};
        cloth.suitProblems.filter((suitProblem) => suitProblem.includes(`${suitPiece}_`)).forEach((suitProblem) => {
          const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
          problems[suitProblemSlug] = [];
          const suitItems = treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug]?.answers?.length === 1
            ? treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug].answers
            : cloth.suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblemSlug);
          suitItems.forEach(({ isMultiple, slug, quantity }) => {
            const suitItemSlug = `${suitPiece}_${slug}`;
            problems[suitProblemSlug].push(suitItemSlug);
            if (isMultiple) problems[`${suitItemSlug}_multiple`] = [quantity];
          });
        });
        const newCloth = getFormattedSuitPiece(index, cloth, suitPiece, problems);
        clothesWithSuitPiecesAndSofaPartsSplitted.push(newCloth);
      });
    } else if (cloth.selectedTypeOfWork === 'creation' && cloth.creationSlug === 'crea_sofa') {
      cloth.sofaParts.forEach((sofaPart) => {
        clothesWithSuitPiecesAndSofaPartsSplitted.push({
          ...cloth,
          index,
          sofaPart,
          sofaPartSubTypes: cloth.sofaPartsSubTypes[sofaPart],
          sofaPartItems: cloth.sofaPartsItems[sofaPart],
          sofaPartNumber: cloth.sofaPartsNumber[sofaPart],
        });
      });
    } else {
      clothesWithSuitPiecesAndSofaPartsSplitted.push({ ...cloth, index });
    }
  });
  return clothesWithSuitPiecesAndSofaPartsSplitted;
};

export const formatClothForSummary = (cloth) => ({
  index: cloth.index,
  name: cloth.name,
  items: cloth.items,
  upcyclingItems: cloth.upcyclingItems || [],
  typeOfWork: cloth?.typesOfWork?.[0],
  total: getClothTotal(cloth),
  fabric: cloth.fabric,
  isCurrent: cloth.isCurrent,
  suitPiece: cloth.suitPiece,
  hasTalkRDV: cloth.hasTalkRDV,
  slug: cloth.slug,
  sofaPartSubTypes: cloth.sofaPartSubTypes,
  sofaPart: cloth.sofaPart,
  process: cloth.process,
  liningsNumber: cloth.liningsNumber,
});

export const getTreeFromCloth = (t, cloth) => {
  if (cloth.selectedTypeOfWork === 'creation') {
    if (cloth.sofaPart) return getTreeSofaCreations(t);
    if (['crea_chai', 'crea_stoo'].includes(cloth.creationSlug)) return getTreeChairCreation(t);
    return getTreeCreation(t);
  }
  if (cloth.selectedProcess === 'PACKAGE') return getTreePackage(t);
  return getTree(t);
};
