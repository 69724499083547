/* eslint-disable max-len */

function formatNameForURL(name) {
  return name.toLowerCase().trim().replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''); // to remove accents from string
}

function getLocalitySeo({
  city, locality, zipcode, language,
}) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  if (language === 'en') {
    return {
      title: `Repair ${localityName} (${completedZipcode}) : Your Tailor at ${localityName} - Tilli`,
      description: `Repair ${localityName} ${zipcode} : Your Tailor at Home for all Repairs ✅Available 7/7 from 7am to 10pm ✓Same Price as Your Local Tailor`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Reparatur ${localityName} (${completedZipcode}) : Ihr Schneider bei ${localityName} - Tilli`,
      description: `Reparatur ${localityName} ${zipcode} : Ihr Schneider zu Hause für alle Reparaturen ✅Verfügbar 7/7 von 7:00 bis 22:00 ✓Preis gleich wie bei Ihrer Lohnschneider`,
    };
  }
  if (language === 'es') {
    return {
      title: `Reparación ${localityName} (${completedZipcode}) : Tu sastre en ${localityName} - Tilli`,
      description: `Reparación ${localityName} ${zipcode} : Tu sastre en casa para todas las reparaciones ✅Disponible 7/7 de 7h a 22h ✓Precio igual que tu sastre de barrio`,
    };
  }
  if (language === 'it') {
    return {
      title: `Riparazione ${localityName} (${completedZipcode}) : Il tuo sarto a ${localityName} - Tilli`,
      description: `Riparazione ${localityName} ${zipcode} : Il tuo sarto a domicilio per tutte le riparazioni ✅Disponibile 7/7 dalle 7:00 alle 22:00 ✓Prezzo uguale al tuo sarto di quartiere`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Reparo ${localityName} (${completedZipcode}) : Seu sastre em ${localityName} - Tilli`,
      description: `Reparo ${localityName} ${zipcode} : Seu sastre em casa para todas as reparacoes ✅Disponivel 7/7 das 7h às 22h ✓Preco igual ao seu sastre de bairro`,
    };
  }
  /* cspell:enable */
  return {
    title: `Retouche ${localityName} (${completedZipcode}) : Votre Couturier à ${localityName} - Tilli`,
    description: `Retouche ${localityName} ${zipcode} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getArrondissementSeo({
  city, zipcode, arrondissement, language,
}) {
  if (language === 'en') {
    return {
      title: `Repair ${city} ${arrondissement} : Your Tailor at ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Repair ${city} ${arrondissement} : Your Tailor at Home for all Repairs ✅Available 7/7 from 7am to 10pm ✓Same Price as Your Local Tailor`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Reparatur ${city} ${arrondissement} : Ihr Schneider bei ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Reparatur ${city} ${arrondissement} : Ihr Schneider zu Hause für alle Reparaturen ✅Verfügbar 7/7 von 7:00 bis 22:00 ✓Preis gleich wie bei Ihrer Lohnschneider`,
    };
  }
  if (language === 'es') {
    return {
      title: `Reparación ${city} ${arrondissement} : Tu sastre en ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Reparación ${city} ${arrondissement} : Tu sastre en casa para todas las reparaciones ✅Disponible 7/7 de 7h a 22h ✓Precio igual que tu sastre de barrio`,
    };
  }
  if (language === 'it') {
    return {
      title: `Riparazione ${city} ${arrondissement} : Il tuo sarto a ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Riparazione ${city} ${arrondissement} : Il tuo sarto a domicilio per tutte le riparazioni ✅Disponibile 7/7 dalle 7:00 alle 22:00 ✓Prezzo uguale al tuo sarto di quartiere`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Reparo ${city} ${arrondissement} : Seu sastre em ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Reparo ${city} ${arrondissement} : Seu sastre em casa para todas as reparacoes ✅Disponivel 7/7 das 7h às 22h ✓Preco igual ao seu sastre de bairro`,
    };
  }
  /* cspell:enable */
  return {
    title: `Retouche ${city} ${arrondissement} : Votre Couturier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Retouche ${city} ${arrondissement} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getLocalityRepairSeo({
  city, locality, zipcode, language,
}) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  if (language === 'en') {
    return {
      title: `Repair ${localityName} (${completedZipcode}) : Your Tailor at ${localityName} - Tilli`,
      description: `Repair ${localityName} ${zipcode} : Your Tailor at Home for all Repairs ✅Available 7/7 from 7am to 10pm ✓Same Price as Your Local Tailor`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Reparatur ${localityName} (${completedZipcode}) : Ihr Schneider bei ${localityName} - Tilli`,
      description: `Reparatur ${localityName} ${zipcode} : Ihr Schneider zu Hause für alle Reparaturen ✅Verfügbar 7/7 von 7:00 bis 22:00 ✓Preis gleich wie bei Ihrer Lohnschneider`,
    };
  }
  if (language === 'es') {
    return {
      title: `Reparación ${localityName} (${completedZipcode}) : Tu sastre en ${localityName} - Tilli`,
      description: `Reparación ${localityName} ${zipcode} : Tu sastre en casa para todas las reparaciones ✅Disponible 7/7 de 7h a 22h ✓Precio igual que tu sastre de barrio`,
    };
  }
  if (language === 'it') {
    return {
      title: `Riparazione ${localityName} (${completedZipcode}) : Il tuo sarto a ${localityName} - Tilli`,
      description: `Riparazione ${localityName} ${zipcode} : Il tuo sarto a domicilio per tutte le riparazioni ✅Disponibile 7/7 dalle 7:00 alle 22:00 ✓Prezzo uguale al tuo sarto di quartiere`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Reparo ${localityName} (${completedZipcode}) : Seu sastre em ${localityName} - Tilli`,
      description: `Reparo ${localityName} ${zipcode} : Seu sastre em casa para todas as reparacoes ✅Disponivel 7/7 das 7h às 22h ✓Preco igual ao seu sastre de bairro`,
    };
  }
  /* cspell:enable */
  return {
    title: `Réparation ${localityName} (${completedZipcode}) : Votre Couturier à ${localityName} - Tilli`,
    description: `Réparation ${localityName} ${zipcode} : Votre Couturier à Domicile pour toutes vos Réparations ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getArrondissementRepairSeo({
  city, zipcode, arrondissement, language,
}) {
  if (language === 'en') {
    return {
      title: `Repair ${city} ${arrondissement} : Your Tailor at ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Repair ${city} ${arrondissement} : Your Tailor at Home for all Repairs ✅Available 7/7 from 7am to 10pm ✓Same Price as Your Local Tailor`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Reparatur ${city} ${arrondissement} : Ihr Schneider bei ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Reparatur ${city} ${arrondissement} : Ihr Schneider zu Hause für alle Reparaturen ✅Verfügbar 7/7 von 7:00 bis 22:00 ✓Preis gleich wie bei Ihrer Lohnschneider`,
    };
  }
  if (language === 'es') {
    return {
      title: `Reparación ${city} ${arrondissement} : Tu sastre en ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Reparación ${city} ${arrondissement} : Tu sastre en casa para todas las reparaciones ✅Disponible 7/7 de 7h a 22h ✓Precio igual que tu sastre de barrio`,
    };
  }
  if (language === 'it') {
    return {
      title: `Riparazione ${city} ${arrondissement} : Il tuo sarto a ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Riparazione ${city} ${arrondissement} : Il tuo sarto a domicilio per tutte le riparazioni ✅Disponibile 7/7 dalle 7:00 alle 22:00 ✓Prezzo uguale al tuo sarto di quartiere`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Reparo ${city} ${arrondissement} : Seu sastre em ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Reparo ${city} ${arrondissement} : Seu sastre em casa para todas as reparacoes ✅Disponivel 7/7 das 7h às 22h ✓Preco igual ao seu sastre de bairro`,
    };
  }
  /* cspell:enable */
  return {
    title: `Réparation ${city} ${arrondissement} : Votre Couturier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Réparation ${city} ${arrondissement} : Votre Couturier à Domicile pour toutes vos Réparations ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getLocalityLeatherGoodsSeo({
  city, locality, zipcode, language,
}) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  if (language === 'en') {
    return {
      title: `Leather Goods ${localityName} (${completedZipcode}) : Your Leather Artisan at ${localityName} - Tilli`,
      description: `Leather Goods ${localityName} ${zipcode} : Your Leather Artisan available at any time thanks to parcel delivery ✓Same Price as Your Local Leather Goods Store`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Lederwaren ${localityName} (${completedZipcode}) : Ihr Lederhandwerker bei ${localityName} - Tilli`,
      description: `Lederwaren ${localityName} ${zipcode} : Ihr Lederhandwerker jederzeit verfügbar dank Paketlieferung ✓Gleicher Preis wie Ihr lokales Lederwarengeschäft`,
    };
  }
  if (language === 'es') {
    return {
      title: `Artículos de cuero ${localityName} (${completedZipcode}) : Tu artesano del cuero en ${localityName} - Tilli`,
      description: `Artículos de cuero ${localityName} ${zipcode} : Tu artesano del cuero disponible en cualquier momento gracias al envío de paquetes ✓Precio igual que tu tienda local de artículos de cuero`,
    };
  }
  if (language === 'it') {
    return {
      title: `Pelletteria ${localityName} (${completedZipcode}) : Il tuo artigiano del cuoio a ${localityName} - Tilli`,
      description: `Pelletteria ${localityName} ${zipcode} : Il tuo artigiano del cuoio disponibile in qualsiasi momento grazie alla consegna dei pacchi ✓Prezzo uguale al tuo negozio locale di pelletteria`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Artigos de couro ${localityName} (${completedZipcode}) : Seu artesão de couro em ${localityName} - Tilli`,
      description: `Artigos de couro ${localityName} ${zipcode} : Seu artesão de couro disponível a qualquer momento graças à entrega de encomendas ✓Preço igual à sua loja local de artigos de couro`,
    };
  }
  /* cspell:enable */
  return {
    title: `Maroquinerie ${localityName} (${completedZipcode}) : Votre artisan maroquinier à ${localityName} - Tilli`,
    description: `Maroquinerie ${localityName} ${zipcode} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Maroquinerie de Quartier`,
  };
}

function getArrondissementLeatherGoodsSeo({
  city, zipcode, arrondissement, language,
}) {
  if (language === 'en') {
    return {
      title: `Leather Goods ${city} ${arrondissement} : Your Leather Artisan at ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Leather Goods ${city} ${arrondissement} : Your Leather Artisan available at any time thanks to parcel delivery ✓Same Price as Your Local Leather Goods Store`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Lederwaren ${city} ${arrondissement} : Ihr Lederhandwerker bei ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Lederwaren ${city} ${arrondissement} : Ihr Lederhandwerker jederzeit verfügbar dank Paketlieferung ✓Gleicher Preis wie Ihr lokales Lederwarengeschäft`,
    };
  }
  if (language === 'es') {
    return {
      title: `Artículos de cuero ${city} ${arrondissement} : Tu artesano del cuero en ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Artículos de cuero ${city} ${arrondissement} : Tu artesano del cuero disponible en cualquier momento gracias al envío de paquetes ✓Precio igual que tu tienda local de artículos de cuero`,
    };
  }
  if (language === 'it') {
    return {
      title: `Pelletteria ${city} ${arrondissement} : Il tuo artigiano del cuoio a ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Pelletteria ${city} ${arrondissement} : Il tuo artigiano del cuoio disponibile in qualsiasi momento grazie alla consegna dei pacchi ✓Prezzo uguale al tuo negozio locale di pelletteria`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Artigos de couro ${city} ${arrondissement} : Seu artesão de couro em ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Artigos de couro ${city} ${arrondissement} : Seu artesão de couro disponível a qualquer momento graças à entrega de encomendas ✓Preço igual à sua loja local de artigos de couro`,
    };
  }
  /* cspell:enable */
  return {
    title: `Maroquinerie ${city} ${arrondissement} : Votre artisan maroquinier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Maroquinerie ${city} ${arrondissement} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Maroquinerie de Quartier`,
  };
}

function getLocalityShoesSeo({
  city, locality, zipcode, language,
}) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  if (language === 'en') {
    return {
      title: `Shoes ${localityName} (${completedZipcode}) : Your Shoemaker at ${localityName} - Tilli`,
      description: `Shoes ${localityName} ${zipcode} : Your Shoemaker available at any time thanks to parcel delivery ✓Same Price as Your Local Shoemaker`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Schuhe ${localityName} (${completedZipcode}) : Ihr Schuhmacher bei ${localityName} - Tilli`,
      description: `Schuhe ${localityName} ${zipcode} : Ihr Schuhmacher jederzeit verfügbar dank Paketlieferung ✓Gleicher Preis wie Ihr lokaler Schuhmacher`,
    };
  }
  if (language === 'es') {
    return {
      title: `Zapatos ${localityName} (${completedZipcode}) : Tu zapatero en ${localityName} - Tilli`,
      description: `Zapatos ${localityName} ${zipcode} : Tu zapatero disponible en cualquier momento gracias al envío de paquetes ✓Precio igual que tu zapatero local`,
    };
  }
  if (language === 'it') {
    return {
      title: `Scarpe ${localityName} (${completedZipcode}) : Il tuo calzolaio a ${localityName} - Tilli`,
      description: `Scarpe ${localityName} ${zipcode} : Il tuo calzolaio disponibile in qualsiasi momento grazie alla consegna dei pacchi ✓Prezzo uguale al tuo calzolaio locale`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Sapatos ${localityName} (${completedZipcode}) : Seu sapateiro em ${localityName} - Tilli`,
      description: `Sapatos ${localityName} ${zipcode} : Seu sapateiro disponível a qualquer momento graças à entrega de encomendas ✓Preço igual ao seu sapateiro local`,
    };
  }
  /* cspell:enable */
  return {
    title: `Cordonnerie ${localityName} (${completedZipcode}) : Votre artisan cordonnier à ${localityName} - Tilli`,
    description: `Cordonnerie ${localityName} ${zipcode} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Cordonnerie de Quartier`,
  };
}

function getArrondissementShoesSeo({
  city, zipcode, arrondissement, language,
}) {
  if (language === 'en') {
    return {
      title: `Shoes ${city} ${arrondissement} : Your Shoemaker at ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Shoes ${city} ${arrondissement} : Your Shoemaker available at any time thanks to parcel delivery ✓Same Price as Your Local Shoemaker`,
    };
  }
  /* cspell:disable */
  if (language === 'de') {
    return {
      title: `Schuhe ${city} ${arrondissement} : Ihr Schuhmacher bei ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Schuhe ${city} ${arrondissement} : Ihr Schuhmacher jederzeit verfügbar dank Paketlieferung ✓Gleicher Preis wie Ihr lokaler Schuhmacher`,
    };
  }
  if (language === 'es') {
    return {
      title: `Zapatos ${city} ${arrondissement} : Tu zapatero en ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Zapatos ${city} ${arrondissement} : Tu zapatero disponible en cualquier momento gracias al envío de paquetes ✓Precio igual que tu zapatero local`,
    };
  }
  if (language === 'it') {
    return {
      title: `Scarpe ${city} ${arrondissement} : Il tuo calzolaio a ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Scarpe ${city} ${arrondissement} : Il tuo calzolaio disponibile in qualsiasi momento grazie alla consegna dei pacchi ✓Prezzo uguale al tuo calzolaio locale`,
    };
  }
  if (language === 'pt') {
    return {
      title: `Sapatos ${city} ${arrondissement} : Seu sapateiro em ${city} ${arrondissement} (${zipcode}) - Tilli`,
      description: `Sapatos ${city} ${arrondissement} : Seu sapateiro disponível a qualquer momento graças à entrega de encomendas ✓Preço igual ao seu sapateiro local`,
    };
  }
  /* cspell:enable */
  return {
    title: `Cordonnerie ${city} ${arrondissement} : Votre artisan cordonnier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Cordonnerie ${city} ${arrondissement} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Cordonnerie de Quartier`,
  };
}

function getSeo(seo) {
  if (seo.arrondissement) return getArrondissementSeo(seo);
  return getLocalitySeo(seo);
}

function getRepairSeo(seo) {
  if (seo.arrondissement) return getArrondissementRepairSeo(seo);
  return getLocalityRepairSeo(seo);
}

function getLeatherGoodsSeo(seo) {
  if (seo.arrondissement) return getArrondissementLeatherGoodsSeo(seo);
  return getLocalityLeatherGoodsSeo(seo);
}

function getShoesSeo(seo) {
  if (seo.arrondissement) return getArrondissementShoesSeo(seo);
  return getLocalityShoesSeo(seo);
}

// cSpell:ignore vetement
function getPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/retouche-vetement/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/retouche-vetement/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/retouche-vetement/${formattedCity}/`.toLowerCase();
}

function getRepairPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/reparation-vetement/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/reparation-vetement/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/reparation-vetement/${formattedCity}/`.toLowerCase();
}

function getLeatherGoodsPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/maroquinerie/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/maroquinerie/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/maroquinerie/${formattedCity}/`.toLowerCase();
}

function getLeatherGoodsPathV2({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/a/reparation/maroquinerie/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/a/reparation/maroquinerie/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/a/reparation/maroquinerie/${formattedCity}/`.toLowerCase();
}

function getShoesPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/cordonnerie/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/cordonnerie/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/cordonnerie/${formattedCity}/`.toLowerCase();
}

function getShoesPathV2({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/a/reparation/cordonnerie/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/a/reparation/cordonnerie/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/a/reparation/cordonnerie/${formattedCity}/`.toLowerCase();
}

function formatUrlPath(path) {
  return path.toLowerCase().replace(/[&/\\#,+().:*?]/g, '').replace(/['"’_ ]/g, '-');
}

module.exports = {
  formatNameForURL,
  getLocalitySeo,
  getArrondissementSeo,
  getSeo,
  getPath,
  getRepairPath,
  getLeatherGoodsPath,
  getLeatherGoodsPathV2,
  getLeatherGoodsSeo,
  getShoesPathV2,
  formatUrlPath,
  getShoesPath,
  getShoesSeo,
  getRepairSeo,
};
