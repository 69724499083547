// see https://lokalise.com/blog/gatsby-i18n/#:~:text=a%20language%20switcher.-,Language%20switcher,-To%20implement%20a for a more complete example
const routes = require('./src/Routes');

const isMultilingual = process.env.GATSBY_LANG === 'EN';

const frLanguageConfig = {
  languages: ['fr'],
  defaultLanguage: 'fr',
  siteUrl: 'https://tilli.fr',
};

const enLanguageConfig = {
  languages: ['en', 'it', 'de', 'es', 'pt'],
  defaultLanguage: 'en',
  siteUrl: 'https://tilli.com',
};

const deeplLanguages = {
  en: 'EN-GB',
  es: 'ES',
  it: 'IT',
  de: 'DE',
  pt: 'PT-PT',
};

module.exports.deeplLanguages = deeplLanguages;

module.exports = isMultilingual ? enLanguageConfig : frLanguageConfig;
module.exports.isMultilingual = isMultilingual;
module.exports.language = isMultilingual ? 'en' : 'fr';

const unavailablePages = [
  routes.Retail.url,
  routes.EventsPage.url,
  routes.RepairBonusPage.url,
];
const unavailablePagesPrefixes = [
  '/retouche-vetement/',
];

module.exports.shouldShowIfEnglish = (url = '') => {
  if (!isMultilingual) return true;
  if (unavailablePagesPrefixes.some((prefix) => url.startsWith(prefix))) return false;
  if (unavailablePages.includes(url)) return false;
  return true;
};
