// when updating pricing version: remember to find all strings in src folder starting by the previous prefix, e.g. "7."
import pricingV14, {
  pricingInverse as pricingInverseAllV14, metadata as metadataAllV14,
  upcyclingPricing as upcyclingPricingAllV14, upcyclingPricingInverse as upcyclingPricingInverseAllV14,
  leatherGoodsPricing as leatherGoodsPricingAllV14, leatherGoodsPricingInverse as leatherGoodsPricingInverseAllV14,
} from 'tilli-core/dist/pricings/v14/pricings';
import pricing, {
  pricingInverse as pricingInverseAll, metadata as metadataAll,
  upcyclingPricing as upcyclingPricingAll, upcyclingPricingInverse as upcyclingPricingInverseAll,
  leatherGoodsPricing as leatherGoodsPricingAll, leatherGoodsPricingInverse as leatherGoodsPricingInverseAll,
} from 'tilli-core/dist/pricings/v13/pricings';
import pricingV12, {
  pricingInverse as pricingInverseAllV12, metadata as metadataAllV12,
  upcyclingPricing as upcyclingPricingAllV12, upcyclingPricingInverse as upcyclingPricingInverseAllV12,
  leatherGoodsPricing as leatherGoodsPricingAllV12, leatherGoodsPricingInverse as leatherGoodsPricingInverseAllV12,
} from 'tilli-core/dist/pricings/v12/pricings';
import pricingV9, {
  pricingInverse as pricingInverseAllV9, metadata as metadataAllV9,
  upcyclingPricing as upcyclingPricingAllV9, upcyclingPricingInverse as upcyclingPricingInverseAllV9,
  leatherGoodsPricing as leatherGoodsPricingAllV9, leatherGoodsPricingInverse as leatherGoodsPricingInverseAllV9,
} from 'tilli-core/dist/pricings/v9/pricings';
import { repairBonusDiscount } from 'tilli-core/dist/services/repairBonus';
import { adjustPricingGridByCountry, adjustMetadataByCountry } from 'tilli-core/dist/pricings/transformInternational';

export const lastPricingVersion = 13;

export function getPricing(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return pricingV9;
  if (pricingVersion === 12) return adjustPricingGridByCountry(pricingV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustPricingGridByCountry(pricingV14, countryCode.toLowerCase());
  return adjustPricingGridByCountry(pricing, countryCode.toLowerCase());
}

export function getPricingInverse(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return pricingInverseAllV9;
  if (pricingVersion === 12) return adjustPricingGridByCountry(pricingInverseAllV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustPricingGridByCountry(pricingInverseAllV14, countryCode.toLowerCase());
  return adjustPricingGridByCountry(pricingInverseAll, countryCode.toLowerCase());
}

export function getMetadata(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return metadataAllV9;
  if (pricingVersion === 12) return adjustMetadataByCountry(metadataAllV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustMetadataByCountry(metadataAllV14, countryCode.toLowerCase());
  return adjustMetadataByCountry(metadataAll, countryCode.toLowerCase());
}

export function getUpcyclingPricing(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return upcyclingPricingAllV9;
  if (pricingVersion === 12) return adjustPricingGridByCountry(upcyclingPricingAllV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustPricingGridByCountry(upcyclingPricingAllV14, countryCode.toLowerCase());
  return adjustPricingGridByCountry(upcyclingPricingAll, countryCode.toLowerCase());
}

export function getUpcyclingPricingInverse(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return upcyclingPricingInverseAllV9;
  if (pricingVersion === 12) return adjustPricingGridByCountry(upcyclingPricingInverseAllV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustPricingGridByCountry(upcyclingPricingInverseAllV14, countryCode.toLowerCase());
  return adjustPricingGridByCountry(upcyclingPricingInverseAll, countryCode.toLowerCase());
}

export function getLeatherGoodsPricing(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return leatherGoodsPricingAllV9;
  if (pricingVersion === 12) return adjustPricingGridByCountry(leatherGoodsPricingAllV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustPricingGridByCountry(leatherGoodsPricingAllV14, countryCode.toLowerCase());
  return adjustPricingGridByCountry(leatherGoodsPricingAll, countryCode.toLowerCase());
}

export function getLeatherGoodsPricingInverse(pricingVersion = lastPricingVersion, countryCode = 'FR') {
  if (pricingVersion === 9) return leatherGoodsPricingInverseAllV9;
  if (pricingVersion === 12) return adjustPricingGridByCountry(leatherGoodsPricingInverseAllV12, countryCode.toLowerCase());
  if (pricingVersion === 14) return adjustPricingGridByCountry(leatherGoodsPricingInverseAllV14, countryCode.toLowerCase());
  return adjustPricingGridByCountry(leatherGoodsPricingInverseAll, countryCode.toLowerCase());
}

export const clothSlugs = ['pant', 'mant', 'robe', 'haut', 'cost', 'pull', 'comb', 'jupe', 'mari', 'mail', 'ling'];
export const interiorsSlugs = ['ride', 'mais', 'cous', 'chair', 'sofa', 'tableClothes'];
export const leatherGoodsSlugs = ['bag', 'belt', 'wallet', 'shoes'];
export const shoesTypes = ['flat_shoes', 'heeled_shoes', 'boots', 'heeled_boots', 'sneakers'];
export const allLeatherGoodsSlugs = [...leatherGoodsSlugs, ...shoesTypes];

export const minTornHoleyPrice = (selectedSizeBag) => {
  if (selectedSizeBag === 'littleBag') {
    return 50;
  } if (selectedSizeBag === 'middleBag') {
    return 85;
  } if (selectedSizeBag === 'bigBag') {
    return 135;
  }
  return 35;
};

export const maxTornHoleyPrice = (selectedSizeBag) => {
  if (selectedSizeBag === 'littleBag') {
    return 80;
  } if (selectedSizeBag === 'middleBag') {
    return 110;
  } if (selectedSizeBag === 'bigBag') {
    return 160;
  }
  return 60;
};

export const clothSlugToElbowPadsPricingIdMap = {
  pull: '13.pull-rep-div-6',
  robe: '13.robe-rep-div-7',
  mant: '13.mant-rep-div-8',
  haut: '13.haut-rep-div-6',
  comb: '13.comb-rep-div-1',
};

export const pricingInverse = pricingInverseAll;
export const metadata = metadataAll;
export const upcyclingPricing = upcyclingPricingAll;
export const upcyclingPricingInverse = upcyclingPricingInverseAll;

export { repairBonusDiscount };

export function formatPrice(price, currency = 'EUR') {
  if (typeof price !== 'number') return '';
  if (currency === 'GBP') {
    if (price === 0) return '£0';
    if (Math.round(price) === price) return `£${price}`;
    return `£${price.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  if (price === 0) return '0€';
  if (Math.round(price) === price) return `${price}€`;
  return `${price.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€`;
}
